import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import morph from '@alpinejs/morph'
import focus from '@alpinejs/focus'
import './components/iframe-auto-size';
import './components/gallery';
import './components/carousel';
import {
    initializePowderguide,
    killOldServiceWorkers,
} from "./api";
import {Linkify} from "./linkify";
import Choices from 'choices.js'

// Global get CSRF token function (used by forms).
window.getToken = async () => {
    return await fetch('/!/statamic-peak-tools/dynamic-token/refresh')
        .then((res) => res.json())
        .then((data) => {
            return data.csrf_token
        })
        .catch((error) => {
            this.error = 'Something went wrong. Please try again later.'
        })
}

/**
 * @param {HTMLElement} element
 * @param {PowderguideApiTokenStorage} tokenStorage
 */
function initializeCrApp(element, tokenStorage) {
    element.setAttribute('data-api-token', tokenStorage.get() ?? '');

    const script = document.createElement('script');
    script.src = `${element.getAttribute('data-app-url')}/static/js/main.js`;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${element.getAttribute('data-app-url')}/static/css/main.css`;

    element.parentElement.appendChild(script)
    element.parentElement.appendChild(link)
}

function initialize() {
    window.Choices = Choices;

    killOldServiceWorkers();
    const {
        tokenStorage,
        powderguideApi,
        powderguideAuthentication,
        powderguideComments,
        powderguideRegistration,
        powderguidePasswordReset,
    } = initializePowderguide();
    const linkify = new Linkify();

    // Call Alpine.
    window.Alpine = Alpine
    Alpine.plugin(collapse)
    Alpine.plugin(morph)
    Alpine.plugin(focus)
    Alpine.directive('linkify', el => linkify.parseFromDomNode(el))
    Alpine.store('powderguideApiAuthentication', powderguideAuthentication)
    Alpine.store('powderguideApi', powderguideApi)
    Alpine.store('powderguideComments', powderguideComments)
    Alpine.store('powderguideRegistration', powderguideRegistration)
    Alpine.store('powderguidePasswordReset', powderguidePasswordReset)
    Livewire.start();

    for (const element of document.querySelectorAll('#ionic-content-root')) {
        initializeCrApp(element, tokenStorage);
    }
}

const isStaticCache = document.querySelector('[data-nocache]') !== null;
if (isStaticCache) {
    document.addEventListener('statamic:nocache.replaced', () => initialize())
} else {
    initialize();
}
